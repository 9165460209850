import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tab-active"
export default class extends Controller {
  addFocus(e){
    this.checkKey(e, this.element)
  }

  // trap focus inside of modal
  checkKey(e, element){
    console.log('Tab event')
    const focusableElements = element.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
    console.log(focusableElements)
    const firstFocusableElement = focusableElements[0]
    const lastFocusableElement = focusableElements[focusableElements.length - 1]

    const focusedElement = document.activeElement;

    // Check if the focused element is a link with class "nav-link"
    if (focusedElement && focusedElement.classList.contains("nav-link")) {
      // Optionally, clear the active class from all nav icons first:
      document.querySelectorAll(".nav-link .side-navbar-icon").forEach(icon => {
        icon.classList.remove("menu-item-active-dashed");
      });
      // Find the child element with class "side-navbar-icon"
      const iconElement = focusedElement.querySelector(".side-navbar-icon");
      if (iconElement) {
        iconElement.classList.add("menu-item-active-dashed");
      }
    }
  }
}

