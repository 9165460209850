import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ['modalOverlay', 'vimeo'];
  static values = { sessions: Number };
  closeOverlay() {
    // Hide the modal overlay
    this.modalOverlayTarget.style.display = 'none';
    this.vimeoTarget.src = ''
    setTimeout(() => {
      this.vimeoTarget.src = "https://player.vimeo.com/video/1007963868?title=0&byline=0&portrait=0&autoplay=1&color=ffffff&loop=0&muted=0#t=46s"
    }, 300)

    
  }

  openOverlay() {
    this.modalOverlayTarget.style.display = 'flex';
  }

  showTutorialPopUp(){
    $('#modal-overlay').toggle('show')
  }
}
