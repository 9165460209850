import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="dropdown"
// In order to reuse this controller, you must wrap the whole dropdown in a div
// called dropdown container with only two children - first child is the button
// that opens the dropdown the last child is the dropdown. Example:
// <div class="relative" data-controller="dropdown">
//   <button type="button" data-action="click->dropdown#handleToggle">
//     Dropdown trigger
//   </button>
//
//   <div data-dropdown-target="dropdown" class="hidden absolute">
//     Dropdown content
//   </div>
// </div>

export default class extends Controller {
  constructor(...args){
    super(...args)
    this.handleClick = this.handleClick.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
  }

  static targets = ['dropdown']

  handleClick(e){
    const sideNavbarIcon = document.querySelector('.side-navbar-icon[data-icon="upload"]');
    if (sideNavbarIcon) {
      document.querySelector('.upload-icon-active').classList.add('hidden');
      document.querySelector('.upload-icon').classList.remove('hidden');
      sideNavbarIcon.classList.remove('menu-item-active');
    }
    const helpIcon = document.querySelector('.help-icon-container');
    if (helpIcon) {
      document.querySelector('.help-icon-active').classList.add('hidden');
      document.querySelector('.help-icon').classList.remove('hidden');
      helpIcon.classList.remove('menu-item-active');
    }

    const userIconContainer = document.querySelector('.user-icon');
    if (userIconContainer) {
      userIconContainer.classList.remove('user-icon-active');
    }

    const mobileUserIconContainer = document.querySelector('.mobile-user-icon');
    if (mobileUserIconContainer) {
      mobileUserIconContainer.classList.remove('mobile-user-icon-active');
    }

    const mobileHelpIconContainer = document.querySelector('.mobile-help-icon');
    if (mobileHelpIconContainer) {
      mobileHelpIconContainer.classList.remove('mobile-help-icon-active');
    }

    // If click is outside dropdown, close dropdown & remove the event listener
    if (!this.dropdownTarget.contains(e.target) && !e.target.classList.contains('exempt-from-dropdown-close')){
      const dropdown = this.element.lastElementChild
      dropdown.classList.add('hidden')
      dropdown.ariaExpanded = 'false'
      document.removeEventListener('click', this.handleClick)
    }
  }

  toggleUploadIcon(e){
    const sideNavbarIcon = document.querySelector('.side-navbar-icon[data-icon="upload"]');
    if (sideNavbarIcon) {
      if (sideNavbarIcon.classList.contains('menu-item-active')) {
        document.querySelector('.upload-icon-active').classList.add('hidden');
        document.querySelector('.upload-icon').classList.remove('hidden');
        sideNavbarIcon.classList.remove('menu-item-active');
      } else {
        document.querySelector('.upload-icon-active').classList.remove('hidden');
        document.querySelector('.upload-icon').classList.add('hidden');
        sideNavbarIcon.classList.add('menu-item-active');
      }
    }
  }

  toggleUserIcon(e){
    const userIconContainer = document.querySelector('.user-icon');
    if (userIconContainer) {
      if (userIconContainer.classList.contains('user-icon-active')) {
        userIconContainer.classList.remove('user-icon-active');
      } else {
        userIconContainer.classList.add('user-icon-active');
      }
    }
  }

  toggleMobileUserIcon(e){
    const mobileUserIconContainer = document.querySelector('.mobile-user-icon');
    if (mobileUserIconContainer) {
      if (mobileUserIconContainer.classList.contains('mobile-user-icon-active')) {
        mobileUserIconContainer.classList.remove('mobile-user-icon-active');
      } else {
        mobileUserIconContainer.classList.add('mobile-user-icon-active');
      }
    }
  }

  toggleMobileHelpIcon(e){
    const mobileHelpIconContainer = document.querySelector('.mobile-help-icon');
    if (mobileHelpIconContainer) {
      if (mobileHelpIconContainer.classList.contains('mobile-help-icon-active')) {
        mobileHelpIconContainer.classList.remove('mobile-help-icon-active');
      } else {
        mobileHelpIconContainer.classList.add('mobile-help-icon-active');
      }
    }
  }

  toggleHelpIcon(e){
    const helpIconContainer = document.querySelector('.help-icon-container');
    if (helpIconContainer) {
      if (helpIconContainer.classList.contains('menu-item-active')) {
        document.querySelector('.help-icon-active').classList.add('hidden');
        document.querySelector('.help-icon').classList.remove('hidden');
        helpIconContainer.classList.remove('menu-item-active');
      } else {
        document.querySelector('.help-icon-active').classList.remove('hidden');
        document.querySelector('.help-icon').classList.add('hidden');
        helpIconContainer.classList.add('menu-item-active');
      }
    }
  }

  close(){
    const dropdown = this.element.lastElementChild
    const button = this.element.firstElementChild
    dropdown.classList.add('hidden')
    dropdown.ariaExpanded = 'false'
    button.focus()
    document.removeEventListener('click', this.handleClick)
  }

  handleToggle(){
    const dropdown = this.element.lastElementChild
    dropdown.classList.toggle('hidden')

    // Click outside of menu to close logic:
    // - if dropdown menu is open, add a click event listener on the whole document
    // - if closed, remove the event listener
    if (Array.from(dropdown.classList).includes('hidden')) {
      dropdown.ariaExpanded = 'false'
      document.removeEventListener('click', this.handleClick)
    } else {
      dropdown.ariaExpanded = 'true'

      setTimeout(() => {
        document.addEventListener('click', this.handleClick)
      }, 100)
    }

    this.addFocusToFirstElement(this.element.lastElementChild)
  }

  addFocus(){
    const dropdown = this.element.lastElementChild
    this.checkKey(dropdown)
  }

  checkKey(element){
    const focusableElements = element.querySelectorAll('button, [href], [tabindex]:not([tabindex="-1"]')
    const focusableElementsArray = Array.from(focusableElements)

    // Arrow keys are used to navigate a dropdown menu
    focusableElements.forEach((element) => {
      element.addEventListener('keydown', (e) => {
        if (e.key === 'ArrowUp' ||  e.key === 'ArrowLeft'){
          e.preventDefault()
          let indexOfPrevElement = focusableElementsArray.indexOf(element) - 1
          // If the focus is on the first element, focus on the last one next
          if (indexOfPrevElement === -1){
            indexOfPrevElement = focusableElements.length - 1
          }
          focusableElementsArray[indexOfPrevElement].focus()
        } else if (e.key === 'ArrowDown' ||  e.key === 'ArrowRight'){
          e.preventDefault()
          let indexOfNextElement = focusableElementsArray.indexOf(element) + 1
          // If the focus is on the last element, focus on the first one next
          if (indexOfNextElement === focusableElements.length){
            indexOfNextElement = 0
          }
          focusableElementsArray[indexOfNextElement].focus()
          // Do not use tab to navigate
        } else if (e.key === 'Tab'){
          e.preventDefault()
        }
      })
    })
  }

  addFocusToFirstElement(dropdown){
    const focusableElements = dropdown.querySelectorAll('button, [href], [tabindex]:not([tabindex="-1"])')
    const firstFocusableElement = focusableElements[0]

    // Specifically for audio speed and document text option dropdowns:
    // Add focus to the button that is active
    if (dropdown.querySelector('.speed-active')){
      dropdown.querySelector('.speed-active').focus()
    } else if (dropdown.querySelector('.document-appearance-options-active')){
      dropdown.querySelector('.document-appearance-options-active').focus()
    } else if (firstFocusableElement) {
      firstFocusableElement.focus()
    }
  }
}
